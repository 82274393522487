<template>
  <v-card
    flat
    class="ma-3 pa-6"
  >
    <v-row align="center" class="pl-0 mb-6 pt-3 pr-3">
      <v-card-title>
        Settings
      </v-card-title>
    </v-row>

    <v-data-table
      :headers="headers"
      :items="items"
      :loading="isSearching"
      disable-pagination
      disable-sort
      disable-filtering
      hide-default-footer
      @click:row="rowClick"
    >
    </v-data-table>


    <v-dialog
      v-model="dialog"
      max-width="700"
      persistent
    >
      <v-card>
        <v-toolbar color="primary" dark flat short class="px-2">
          <v-toolbar-title>User</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn @click="dialog = false" icon><v-icon>fas fa-times</v-icon></v-btn>
        </v-toolbar>

        <v-card-text class="mt-12">
          <v-form @submit.prevent>

            <v-text-field
              outlined
              class="mb-2"
              v-model="model.description"
              label="Description"
              readonly
            ></v-text-field>

            <v-text-field
              outlined
              class="mb-2"
              v-model="model.value"
              :error-messages="errors.value"
              label="Value"
            ></v-text-field>

          </v-form>
        </v-card-text>

        <v-card-actions class="px-6 pb-8">
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            class="px-5"
            large
            outlined
            depressed
            @click="dialog = false"
          >Cancel</v-btn>
          <v-btn
            color="primary"
            class="px-5"
            large
            depressed
            @click="saveClick()"
          >Save</v-btn>
        </v-card-actions>

      </v-card>

    </v-dialog>

  </v-card>
  
</template>

<script>
import { inject, ref } from 'vue'

export default {
  setup() {
    const api = inject('api')
    const loader = inject('loader')

    const settingCollection = api.collection('settings', {
      params: {
      },
    })

    const { items, isSearching } = settingCollection.all()

    const headers = [
      { text: 'Description', value: 'description', width: '50%' },
      { text: 'Value', value: 'value', width: '50%' },
    ]

    const dialog = ref(false)

    const settingModel = api.model('settings/:id', {
      params: {
      },
      fields: {
      },
    })
    const { attributes: model, errors, isBusy } = settingModel

    loader.bindRef(isBusy)

    function rowClick(item) {
      settingModel.find(item.id).then(() => {
        dialog.value = true
      })
    }

    function saveClick() {
      settingModel.save().then(() => {
        settingCollection.all()
        dialog.value = false
      })
    }

    return {
      headers,
      items,
      isSearching,
      rowClick,
      saveClick,
      dialog,
      model,
      errors,
    }

  },
}
</script>


<style scoped>

:deep(.v-data-table table tbody tr:not(.v-data-table__empty-wrapper):hover) {
  cursor: pointer;
}

</style>
